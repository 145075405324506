<template>
  <div
    class="px-8 pb-10 container process-hazard-analysis"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper mr-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <div class="imageframe-align-center">
            <span
              class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
              ><img
                src="@/assets/RiskManagement/PHA-min.jpg"
                width="528"
                height="337"
                alt="process hazards analysis"
                title="Process Hazards Analysis PHA"
                class="img-responsive wp-image-12495"
            /></span>
          </div>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;"
          ></div>
          <span
            class="fusion-imageframe imageframe-none imageframe-2 hover-type-none"
            ><img
              src="@/assets/RiskManagement/pha_flow-min.png"
              width="556"
              height="528"
              alt=""
              title="PHA Flow"
              class="img-responsive wp-image-11830"
          /></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:-20px;"
          ></div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <TitleComponent title="Process Hazards Analysis" size="large" />
          <div class="fusion-text">
            <Paragraph>
              <p>
                Process Hazards Analysis (PHA) is a component of both Process
                Safety Management (PSM) and Risk Management Programs (RMP).
              </p>
              <p>Our approach to risk management emphasizes:</p>
              <p></p>
              <ol>
                <li>Minimizing personnel exposure</li>
                <li>Minimizing quantities of hazardous materials</li>
                <li>“Safety by Design”</li>
                <li>Accurate procedures and standards</li>
                <li>Rigorous personnel training</li>
              </ol>
              <p>
                Proper risk management focuses on not only normal
                operations/conditions but also abnormal operations/conditions,
                equipment design, human factors, standard operating and
                contingency procedures, maintenance operations, and facility
                design and siting.
              </p>
              <p>
                The Safety Management Services, Inc. (SMS) approach to risk
                management focuses resources on addressing critical credible
                failure scenarios. This is accomplished by prioritizing
                operations/equipment based on perceived risk and material type
                and quantity, identifying and ranking potential hazards using
                qualitative methodologies, and then using quantitative
                methodologies to evaluate the critical scenarios. This type of
                review is known to industry as risk assessment or Process
                Hazards Analysis (PHA). These PHA services may be used
                independently or in conjunction with our explosive
                characterization, incident investigation, facility siting, and
                other analysis services. Our PHA reports are constructed using
                SMS proprietary software, however, we also utilize other
                industry-accepted software such as PHA Pro when requested.
              </p>
              <p>
                SMS has unique capabilities and experience comparing in-process
                energies to the material’s explosive response. We have developed
                effective methods for measuring, calculating, and/or modeling
                the normal and potential abnormal in-process energies in
                engineering units. Our methodology allows us to compare this
                information with the explosive response data, which is also in
                engineering units.
              </p>
              <p>
                SMS performs consequence analysis on incident scenarios to
                determine the potential effects of thermal flux, fragments, and
                overpressure on personnel, equipment, and facilities.
                Understanding the nature of potential failure scenarios,
                in-process energies, material response and consequences allows
                SMS personnel to make recommendations for process/equipment
                design, system operating parameters, and facility design and
                siting.
              </p>
              <p>
                SMS takes considerable pride in assisting our clients in
                identifying risk-minimizing solutions that are based on sound
                engineering principles and are cost effective. We can team up
                with your personnel to provide a thorough analysis of planned,
                modified, or existing processes.
              </p>
            </Paragraph>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6">
        <div
          class="fusion-column-wrapper mr-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <TitleComponent
            title="Process Hazards Analysis Methodologies Used by SMS"
          />
          <TabsComponent :tabs="tabs" />
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <div
            class="fusion-separator fusion-full-width-sep sep-single sep-solid"
            style="border-color:#aa212c;border-top-width:1px;margin-left: auto;margin-right: auto;margin-top:;margin-bottom:60px;"
          ></div>
        </div>
        <div
          class="mb-4 group cursor-pointer bg-white rounded max-w-xs w-full shadow-lg select-none overflow-hidden mx-auto"
        >
          <router-link
            to="/risk-management/process-hazards-analysis/fmea-basis"
          >
            <div class="p-4">
              <p
                class="font-semibold text-lg mb-1 text-gray-900 group-hover:text-white"
              >
                FMEA Basis
              </p>
              <p class="text-gray-700 group-hover:text-white mb-2">
                FAILURE MODES AND EFFECTS ANALYSIS
              </p>
            </div>
          </router-link>
        </div>
        <div
          class="mb-4 group cursor-pointer bg-white rounded max-w-xs w-full shadow-lg select-none overflow-hidden mx-auto"
        >
          <router-link
            to="/risk-management/process-hazards-analysis/hazop-basis"
          >
            <div class="p-4">
              <p
                class="font-semibold text-lg mb-1 text-gray-900 group-hover:text-white"
              >
                HAZOP Basis
              </p>
              <p class="text-gray-700 group-hover:text-white mb-2">
                HAZARD AND OPERABILITY ANALYSIS
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paragraph from "@/components/Paragraph.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import TabsComponent from "@/components/TabsComponent.vue";

export default {
  metaInfo: {
    title: "Process Hazard Analysis",
    titleTemplate: "%s | Safety Management Services, Inc.",
    meta: [
      {
        name: "description",
        content:
          "Process Hazards Analysis (PHA) focuses on identifying hazards, safeguards, and recommendations for mitigating risk. It is a fundamental aspect of Process Safety Management, Risk Management Programs, and Facility Siting. SMS has unique and extensive experience in performed PHAs for energetic materials, processes, and facilities."
      }
    ]
  },
  components: {
    Paragraph,
    TitleComponent,
    TabsComponent
  },
  data: () => {
    return {
      tabs: [
        {
          name: "Quantitative Risk Assessment",
          content: `<ul>
                    <li>Job Safety Analysis (JSA)</li>
                    <li>Logic diagrams</li>
                    <li>What-if/Checklist</li>
                    <li>Failure Modes and Effects Analysis (FMEA)</li>
                    <li>Hazard and Operability Study (HAZOP)</li>
                   </ul>`,
          html: true
        },
        {
          name: "Quantitative Fault Assessment",
          content: `<ul>
                    <li>Fault Tree Analysis (FTA)</li>
                    <li>Probit statistical analysis</li>
                    <li>In-process energy modeling</li>
                    <li>Event probabilities</li>
                    <li>Risk/cost trade-off</li>
                   </ul>`,
          html: true
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.group:hover {
  background-color: #aa222c;
  p {
    @apply text-white;
  }
}
</style>
